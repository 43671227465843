import React, { useState } from 'react';
import './SignIn.css';
import logo from '../logo.png';

const SignIn = ({ onNext }) => {
    const [inputValue, setInputValue] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleNextClick = () => {
        if (!inputValue) {
            setErrorMessage('Enter an email or phone number.');
        } else if (!validateEmailOrPhone(inputValue)) {
            setErrorMessage('Enter a valid email or phone number.');
        } else if (isBogusEmail(inputValue) || isGibberish(inputValue)) {
            setErrorMessage('Couldn\'t find your Jamie Account');
        } else {
            setErrorMessage('');
            onNext();
        }
    };

    const validateEmailOrPhone = (value) => {
        // Basic email/phone validation logic
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const phoneRegex = /^[0-9]{7,}$/;
        return emailRegex.test(value) || phoneRegex.test(value);
    };

    const isBogusEmail = (value) => {
        // Check for bogus email or phone
        const bogusEmails = ['fuckyou@fuckyou.com'];
        const bogusPhones = ['01234567'];
        return bogusEmails.includes(value.toLowerCase()) || bogusPhones.includes(value);
    };

    const isGibberish = (value) => {
        // Enhanced gibberish detection logic
        const repeatedCharRegex = /(.)\1{2,}/;
        const vowelRegex = /[aeiou]{3,}/i;
        const consonantRegex = /[bcdfghjklmnpqrstvwxyz]{4,}/i;
        const randomCharRegex = /^[a-z0-9]+@[a-z0-9]+\.[a-z]+$/i;
        const dictionaryWords = ['test', 'email', 'example', 'valid', 'address']; // Add more common words to this list
        const words = value.split(/[@.]/);

        // Check if any word is in the dictionary
        const isDictionaryWord = words.some(word => dictionaryWords.includes(word.toLowerCase()));

        return repeatedCharRegex.test(value) || vowelRegex.test(value) || consonantRegex.test(value) || !randomCharRegex.test(value) || !isDictionaryWord;
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleNextClick();
        }
    };

    return (
        <div className="App">
            <div className="container">
                <div className="left-section">
                    <div className="logo-container">
                        <img src={logo} alt="Logo" className="logo" />
                    </div>
                    <div className="text-container">
                        <div className="sign-in-text">Contact me</div>
                        <div className="account-text">Use your Jamie Account</div>
                    </div>
                </div>
                <div className="right-section">
                    <div className="input-group">
                        <div className={`input-container ${errorMessage ? 'error' : ''}`}>
                            <input
                                type="text"
                                id="email"
                                className="input"
                                placeholder=" "
                                value={inputValue}
                                onChange={(e) => setInputValue(e.target.value)}
                                onKeyDown={handleKeyDown} // Ensure this is attached to the input
                                required
                            />
                            <label htmlFor="email" className="input-label">Email or phone</label>
                            {errorMessage && (
                                <div className="error-message">
                                    <svg aria-hidden="true" className="error-icon" fill="currentColor" focusable="false" width="16px" height="16px" viewBox="0 0 24 24" xmlns="https://www.w3.org/2000/svg">
                                        <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z"></path>
                                    </svg>
                                    {errorMessage}
                                </div>
                            )}
                        </div>
                        <a href="#" className="forgot-email">Forgot email?</a>
                    </div>
                    <div className="info-text">
                        <p>Not your computer? Use Guest mode to sign in privately.</p>
                        <a href="#" className="learn-more">Learn more about using Guest mode</a>
                    </div>
                    <div className="actions">
                        <button className="create-account">Create account</button>
                        <button className="next-button" onClick={handleNextClick}>Next</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SignIn;
