import React, { useState } from 'react';
import './Welcome.css';
import logo from '../logo.png';
import ReCAPTCHA from 'react-google-recaptcha';

const Welcome = ({ email, onSend }) => {
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [subjectError, setSubjectError] = useState('');
  const [messageError, setMessageError] = useState('');
  const [isVerified, setIsVerified] = useState(false);

  const handleSendClick = () => {
    let valid = true;

    if (!subject) {
      setSubjectError('Please complete all required fields.');
      valid = false;
    } else {
      setSubjectError('');
    }

    const wordCount = message.trim().split(/\s+/).length;
    if (!message) {
      setMessageError('Please complete all required fields.');
      valid = false;
    } else if (wordCount < 200) {
      setMessageError('Please write 200 words before sending to avoid spam.');
      valid = false;
    } else if (containsGibberish(message)) {
      setMessageError('Please use correct language format.');
      valid = false;
    } else {
      setMessageError('');
    }

    if (valid && isVerified) {
      onSend();
    }
  };

  const handleRecaptchaChange = (value) => {
    if (value) {
      setIsVerified(true);
    } else {
      setIsVerified(false);
    }
  };

  const containsGibberish = (text) => {
    // Simple gibberish detection logic, can be improved
    const words = text.trim().split(/\s+/);
    const gibberishRegex = /[a-zA-Z]{10,}/;
    return words.some(word => gibberishRegex.test(word));
  };

  return (
    <div className="App">
      <div className="container">
        <div className="left-section">
          <div className="logo-container">
            <img src={logo} alt="Logo" className="logo" />
          </div>
          <div className="text-container">
            <div className="sign-in-text">Welcome</div>
            <div className="dropdown-container">
              <div className="dropdown-content">
                <img src="profile_icon.png" alt="Profile Icon" className="profile-icon" />
                <span className="email">{email}</span>
                <svg aria-hidden="true" className="dropdown-arrow" fill="currentColor" focusable="false" width="24px" height="24px" viewBox="0 0 24 24" xmlns="https://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"></path></svg>
              </div>
            </div>
          </div>
        </div>
        <div className="right-section">
          <div className="input-group">
            <div className={`input-container ${subjectError ? 'error' : ''}`}>
              <input
                type="text"
                id="subject"
                className="input"
                placeholder=" "
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                required
              />
              <label htmlFor="subject" className="input-label">Subject</label>
              {subjectError && <div className="error-message">{subjectError}</div>}
            </div>
            <div className={`input-container message ${messageError ? 'error' : ''}`}>
              <textarea
                id="message"
                className="input"
                placeholder=" "
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                required
              />
              <label htmlFor="message" className="input-label">Message</label>
              <div className="word-count">{message.trim().split(/\s+/).length} words</div>
              {messageError && <div className="error-message">{messageError}</div>}
            </div>
            <ReCAPTCHA
              sitekey="YOUR_RECAPTCHA_SITE_KEY"
              onChange={handleRecaptchaChange}
            />
            <button
              className="next-button"
              onClick={handleSendClick}
            >
              Send
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
